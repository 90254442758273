<template>
  <div class="page-wrapper">
    <div class="landing-page">
      <p class="sponsored-by">Business Support Tools. Sponsored by</p>
      <div class="header-container">
        <SwoopLogo class="lloyds-logo" height="55px"/>
        <h1 class="header">Simple, Speedy, Secure</h1>
      </div>
      <div class="grants-container">
        <div class="grants-header">
          <div class="grants-finder">
            <h1 class="grants-finder-title">Grantfinder</h1>
            <h2 class="grants-finder-subtitle">Use this quick and easy grantfinder tool to find relevant grant for your business</h2>
            <div class="grants-button-container">
              <button @click="redirectToGoalsJourney" class="btn grants-finder-button">Find grants</button>
            </div>
          </div>
          <img src="@/assets/images/lloydsbank/tree.png" alt="landing page image" class="landing-image"/>
        </div>
        <div class="grants-content">
          <ul>
            <h3>Innovation grants </h3>
            <p>Grants for new ideas and innovation</p>

            <li>Innovation programs > 6 months</li>
            <li>Business and research collaborations</li>
            <li>Overcoming technical challenges to commercialisation</li>
          </ul>
          <ul>
            <h3>Regional grants </h3>
            <p>Grants for business expansion</p>

            <li>Capital & revenue grants for expansion</li>
            <li>Energy efficiency grants</li>
            <li>Community/social enterprise</li>
          </ul>
          <ul>
            <h3>National grants </h3>
            <p>Grants based on government initiatives</p>

            <li>COVID business interruption</li>
            <li>Business rates relief</li>
            <li>Access to broadband</li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import checkGrantFinderRedirects from './mixins/checkGrantFinderRedirects'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import Footer from './components/Footer'
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'landing',
  mixins: [checkGrantFinderRedirects, amplitudeTracking],
  components: { Footer, SwoopLogo },
  mounted() {
    this.identifyUser()
    this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'First Page')
  },
  data() {
    return {
      group: 'lloyds'
    }
  },
  methods: {
    identifyUser() {
      this.$ma.identify({
        group: this.group
      })
    },
    redirectToGoalsJourney() {
      this.$router.push({ name: 'lloyds-form' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

body {
  background: var(--color-error-50);
  font-family: $font-primary ;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  background: $color-whisper;

  .navbar {
    max-width: 1335px;
  }

  .landing-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1335px;
    border-radius: 25px;
    padding: 10px 40px 40px 40px;
    margin: 30px 30px 30px 30px;
    background: $color-white;

    h1 {
      margin: 0;
      font-size: $lh-lg-mobile;
      font-weight: 700;
      line-height: 42px;
    }

    h2,b {
      font-size: $font-size-base;
      font-weight: 700;
    }

    .sponsored-by {
      line-height: 21px;
      min-width: 100px;
    }

    .header-container {
      display: flex;
      width: 100%;
      margin-bottom: 20px;

      .header {
        text-align: center;
        width: 80%;
        margin-right: 200px;
      }
    }

    .grants-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;

      .grants-header {
          display: flex;
          justify-content: space-between;
          width: 90%;

        .grants-finder {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          width: 50%;

          .grants-finder-title{
            font-size: 32px;
          }

          .grants-finder-subtitle {
            font-size: $font-size-lg-mobile;
            font-weight: 550;
          }
          .grants-button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .grants-finder-button {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 55px;
              width: 184px;
              margin: 0px;
              background-color: $color-primary-button;
              color: $color-white;

              &:hover {
                cursor: pointer;
                background-color: $color-primary-button-hover;
                transition: background-color .2s ease-in-out;
              }
            }
            .signin-text {
              font-size: 14px;
              text-decoration: none;
              color: var(--color-primary-400);
            }
          }
        }

        .landing-image {
          max-width: 360px;
        }
      }

      .grants-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin-top: 30px;
        gap: 0 30px;

        ul {
          padding: 0;
          p {
            margin: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .landing-page {
      .header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;

        .lloyds-logo {
          margin-bottom: 20px;
        }

        .header {
          text-align: center;
          margin: 0;
        }
      }

      .grants-container {
        width: 100%;
        margin-top: 0;

        .grants-header {
          flex-wrap: wrap;
          justify-content: center;

          .grants-finder {
            width: 100%;
            justify-content: center;
            padding: 0;
          }

          .landing-image {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .landing-page {

      .header {
        font-size: $fs-lg-mobile;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .landing-page {
      padding: 12px;
      margin: 0;
    }
  }
}
</style>
