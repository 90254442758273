<template>
<div class="footer">
    <p>
      <strong>Disclaimer:</strong> Swoop Finance Limited (Swoop Funding) is working with Lloyds Bank to provide the
      Grantfinder Tool. Swoop Funding is paid a license fee under its arrangement with Lloyds Bank. Swoop Funding helps
      UK firms access business finance, working directly with businesses and their trusted advisors. We are a credit
      broker and do not provide loans or other finance products ourselves. All finance and quotes are subject to status
      and income. Applicants must be aged 18 and over and terms and conditions apply. Guarantees and indemnities may be
      required. Swoop Funding can introduce applicants to a number of providers based on the applicants’ circumstances
      and creditworthiness. Swoop Funding may receive a commission or finder’s fee for effecting such introductions.
      Swoop Finance Limited. is authorised as a credit broker under FCA registration number 936513. If you feel you have
      a complaint, please read our complaints section highlighted below and also contained within our terms and conditions.
    </p>
    <div class="legal-banner">
      <strong>Legal: </strong>
      <a href="https://swoopfunding.com/cookie-policy/">Cookie Policy</a>,
      <a href="https://swoopfunding.com/privacy-policy/">Privacy Policy</a>,
      <a href="https://swoopfunding.com/terms-conditions/">Terms and conditions</a>,
      <a href="https://swoopfunding.com/treating-customers-fairly/">Treating customers fairly</a>,
      <a href="https://swoopfunding.com/disclosure/">Disclosure</a>,
      <a href="https://swoopfunding.com/complaints/">Complaints</a>
    </div>
    <ul class="locations">
      <li>
        <span class="material-icons">
          location_on
        </span>
        <strong>London</strong> 7 Royalty Mews, W1D 3AS, London, UK
      </li>
      <li>
        <span class="material-icons">
          location_on
        </span>
        <strong>Dublin</strong> Dogpatch Labs, The CHQ Building, Custom House Quay, Dublin, Ireland
      </li>
      <li>
        <span class="material-icons">
          location_on
        </span>
        <strong>Sydney</strong> Level 2/477 Pitt Street, Sydney, NSW, 2000, Australia
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/swoop/_variables.scss';

  .footer {
    border-top: 1px solid var(--color-secondary-50);
    margin-top: 72px;
    color: $color-footer-text;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    flex-direction: column;
    gap: 20px;

    strong {
      font-weight: 700;
      margin-right: 4px;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    .legal-banner {
      display: flex;
      flex-wrap: wrap;
      white-space: pre;
      color: $color-footer-text;
      font-size: 14px;

      strong {
        font-weight: 700;
        margin-right: 4px;
      }

      a {
        color: $color-footer-text;
        text-decoration: none;
      }
    }

    .locations {
      display: flex;
      justify-content: space-between;
      text-align: left;
      gap: 20px;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        display: flex;
        align-content: center;

        span.material-icons {
          color: $color-primary;
          margin-right: 4px;
          margin-left: -3px;
        }
      }

      @media only screen and (max-width: $breakpoint-sm-max) {
        flex-direction: column;
      }
    }
  }
</style>
